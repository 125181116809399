@import '../../scss/variables';

.testimonial-card {
    background: white;
    border-radius: 16px;
    padding: 2rem;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    height: 100%;
    width: 100%; // Ensure card takes full width of slide
    box-sizing: border-box;
    
    &__quote-icon {
      position: absolute;
      top: -1rem;
      right: 1rem;
      color: $burgundy;
      font-size: 3rem;
      opacity: 0.1;
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    &__header {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  
    &__avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }
  
    &__info {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  
    &__name {
      font-size: 1.125rem;
      font-weight: 600;
      color: $burgundy;
      margin: 0;
    }
  
    &__type {
      font-size: 0.875rem;
      color: $gray-dark;
      margin: 0;
    }
  
    &__review {
      font-size: 1rem;
      line-height: 1.6;
      color: $gray-dark;
      margin: 0;
    }
  }
  
  .star-rating {
    display: flex;
    gap: 0.25rem;
  }
  
  .star {
    color: #D1D5DB;
    font-size: 1.25rem;
    
    &--filled {
      color: #FFA41C;
    }
  }
  