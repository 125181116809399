$pink-light: rgba(249, 220, 220, 1);
$pink-lighter: rgba(255, 241, 241, 1);
$burgundy: rgba(81, 0, 0, 1);
$red-primary: #E57373;
$gray-dark: #4A5568;
$gray-light: #E2E8F0;

$icon-bg: rgba(#67171D, 0.1);

$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

