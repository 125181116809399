@import '../../scss/variables';

.product-section-two {
    padding: 4rem 2rem;
    background-color: $pink-lighter;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  
    &__main-title {
      color: $burgundy;
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin: 0;
      line-height: 1.2;
  
      @media (max-width: $breakpoint-md) {
        font-size: 2rem;
      }
    }
  
    &__hero {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
      align-items: center;
  
      @media (max-width: $breakpoint-lg) {
        grid-template-columns: 1fr;
        gap: 2rem;
      }
    }
  
    &__image-wrapper {
      position: relative;
      border-radius: 24px 24px 24px 0;
      overflow: hidden;
    //   background: white;
      padding: 8px;
    //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    }
  
    &__image {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 20px 20px 20px 0;
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.9rem;
    }
  
    &__title {
      color: $burgundy;
      font-size: 2rem;
      font-weight: 700;
      margin: 0;
  
      @media (max-width: $breakpoint-md) {
        font-size: 1.75rem;
      }
    }
  
    &__description {
      color: $gray-dark;
      font-size: 0.9rem;
      line-height: 1.8;
      margin: 0;
    }
  
    &__features {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
  
      @media (max-width: $breakpoint-lg) {
        grid-template-columns: repeat(2, 1fr);
      }
  
      @media (max-width: $breakpoint-sm) {
        grid-template-columns: 1fr;
      }
    }
  
    @media (max-width: $breakpoint-md) {
      padding: 4rem 1.5rem;
    }
  }