@import '../../scss/variables';

.strategy {
    background-color: $pink-lighter;
    padding: 4rem 2rem;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
    }
  
    &__title {
      color: $burgundy;
      font-size: 2.5rem;
      font-weight: 700;
      text-align: center;
      margin: 0 0 3rem 0;
    }
  
    &__grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
  
      @media (max-width: $breakpoint-lg) {
        grid-template-columns: repeat(2, 1fr);
      }
  
      @media (max-width: $breakpoint-sm) {
        grid-template-columns: 1fr;
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }