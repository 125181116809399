@import '../../scss/variables';

.supporters-section {
    background-color: #fff;
    padding: 4rem 2rem;
    
    &__container {
      max-width: 1400px;
      margin: 0 auto;
    }
  
    &__header {
      text-align: center;
      margin-bottom: 2rem;
    }
  
    &__title {
      color: $burgundy;
      font-size: 2.5rem;
      font-weight: 700;
      margin: 0 0 0.5rem;
    }
  
    &__subtitle {
      color: $gray-dark;
      font-size: 1.125rem;
      margin: 0;
    }
  
    &__carousel {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  
    &__track-container {
      flex: 1;
      overflow: hidden;
    }
  
    &__track {
      display: flex;
      transition: transform 0.5s ease;
      gap: 1rem;
    }
  
    &__slide {
      flex: 0 0 calc((100% - 4rem) / 5); // Show 5 cards with gaps
      min-width: calc((100% - 4rem) / 5);
      padding-bottom: 10px;
      padding-top: 10px;
    }
  
    &__nav {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: $burgundy;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: white;
      transition: all 0.3s ease;
      flex-shrink: 0;
      padding: 0;
  
      &:disabled {
        background: lighten($burgundy, 10%);
        cursor: not-allowed;
      }
  
      &:not(:disabled) {
        &:hover {
          background: darken($burgundy, 10%);
        }
      }
  
      svg {
        width: 16px;
        height: 16px;
      }
    }
  
    @media (max-width: $breakpoint-xl) {
      &__slide {
        flex: 0 0 calc((100% - 3rem) / 4); // Show 4 cards
        min-width: calc((100% - 3rem) / 4);
      }
    }
  
    @media (max-width: $breakpoint-lg) {
      &__slide {
        flex: 0 0 calc((100% - 2rem) / 3); // Show 3 cards
        min-width: calc((100% - 2rem) / 3);
      }
    }
  
    @media (max-width: $breakpoint-md) {
      padding: 3rem 1rem;
  
      &__slide {
        flex: 0 0 calc((100% - 1rem) / 2); // Show 2 cards
        min-width: calc((100% - 1rem) / 2);
      }
  
      &__title {
        font-size: 2rem;
      }
    }
  
    @media (max-width: $breakpoint-sm) {
      &__slide {
        flex: 0 0 100%; // Show 1 card
        min-width: 100%;
      }
    }
  }