@import '../../scss/variables';

.product-section-one {
    padding: 3rem 2rem;
    background-color: white;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      align-items: center;
  
      @media (max-width: $breakpoint-lg) {
        grid-template-columns: 1fr;
        gap: 3rem;
      }
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    &__title {
      font-size: 2rem;
      font-weight: 700;
      color: $burgundy;
      margin: 0;
      line-height: 1.2;
  
      @media (max-width: $breakpoint-md) {
        font-size: 2rem;
      }
    }
  
    &__subtitle {
      font-size: 0.9rem;
      color: $gray-dark;
      margin: 0;
      line-height: 1.6;
    }
  
    &__stats {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    &__description {
      font-size: 0.9rem;
      color: $gray-dark;
      margin: 0;
      line-height: 1.6;
    }
  
    &__images {
      position: relative;
      display: flex;
      justify-content: center;
  
      @media (max-width: $breakpoint-lg) {
        margin-top: 2rem;
      }
    }
  
    &__image-main {
      width: 80%;
      border-radius: 24px;
      overflow: hidden;
      padding: 8px;
  
      img {
        width: 100%;
        height: auto;
        border-radius: 16px;
        display: block;
      }
    }
  
    &__image-secondary {
      position: absolute;
      bottom: -2rem;
      right: 0;
      width: 50%;
      border-radius: 24px;
      overflow: hidden;
      padding: 8px;
  
      img {
        width: 100%;
        height: auto;
        border-radius: 16px;
        display: block;
      }
    }
  }
  
  .stat-card {
    background-color: $pink-lighter;
    border-radius: 12px;
    padding: 0.8rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    border: 1px solid #E37070;
  
    svg {
      width: 24px;
      height: 24px;
      color: #fff;
      padding: 8px;
      background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
      border-radius: 8px;
      box-shadow: 0 2px 6px rgba($burgundy, 0.1);
    }
  
    &__content {
      flex: 1;
    }
  
    &__percentage {
      font-size: 1.5rem;
      font-weight: 700;
      color: $burgundy;
      margin: 0 0 0.1rem;
    }
  
    &__description {
      font-size: 0.875rem;
      color: #000;
      margin: 0;
      line-height: 1.5;
    }
  }
  