@import '../../scss/variables';

.testimonial-section {
    background-color: $pink-lighter;
    padding: 6rem 2rem;
    
    &__container {
      max-width: 1400px; // Increased to accommodate 3 full cards
      margin: 0 auto;
      padding: 0 1.5rem;
    }
  
    &__title {
      text-align: center;
      color: $burgundy;
      font-size: 2.5rem;
      font-weight: 700;
      margin: 0 0 4rem;
  
      @media (max-width: $breakpoint-md) {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
    }
  
    &__carousel {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  
    &__cards {
      width: 100%;
      overflow: hidden;
    }
  
    &__track {
      display: flex;
      transition: transform 0.5s ease;
      gap: 1.5rem; // Consistent gap between cards
    }
  
    &__slide {
      flex: 0 0 calc((100% - 3rem) / 3); // Distribute space evenly among 3 cards with gaps
      min-width: calc((100% - 3rem) / 3); // Ensure consistent width
      
      &:not(:last-child) {
        margin-right: 0; // Remove default margin as we're using gap
      }
    }
  
    &__nav {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: $burgundy;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: white;
      transition: all 0.3s ease;
      flex-shrink: 0;
      z-index: 2;
  
      &:hover {
        background: darken($burgundy, 10%);
        transform: scale(1.1);
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba($burgundy, 0.5);
      }
  
      svg {
        width: 16px;
        height: 16px;
      }
    }
  
    @media (max-width: $breakpoint-xl) {
      &__container {
        max-width: 1200px;
      }
    }
  
    @media (max-width: $breakpoint-lg) {
      &__slide {
        flex: 0 0 calc((100% - 1.5rem) / 2); // Show 2 cards on tablet
        min-width: calc((100% - 1.5rem) / 2);
      }
    }
  
    @media (max-width: $breakpoint-md) {
      padding: 4rem 1rem;
  
      &__slide {
        flex: 0 0 100%; // Show 1 card on mobile
        min-width: 100%;
      }
  
      &__nav {
        width: 28px;
        height: 28px;
  
        svg {
          width: 14px;
          height: 14px;
        }
      }
  
      &__track {
        gap: 1rem;
      }
    }
  }