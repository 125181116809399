@import '../../scss/variables';

.product-section-three {
    padding: 6rem 2rem;
    background-color: white;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }
  
    &__title {
      color: $burgundy;
      font-size: 2.5rem;
      font-weight: 700;
      text-align: center;
      margin: 0;
      line-height: 1.2;
  
      @media (max-width: $breakpoint-md) {
        font-size: 2rem;
      }
    }
  
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
      align-items: flex-start;
  
      @media (max-width: $breakpoint-lg) {
        grid-template-columns: 1fr;
        gap: 3rem;
      }
    }
  
    &__features {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  
    &__feature {
      display: flex;
      gap: 1rem;
      align-items: flex-start;
    }
  
    &__check {
      color: #000;
      background-color: $pink-lighter;
      padding: 4px;
      border-radius: 50%;
      flex-shrink: 0;
      margin-top: 2px;
    }
  
    &__feature-text {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  
    &__feature-title {
      color: $burgundy;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
    }
  
    &__feature-description {
      color: $gray-dark;
      font-size: 0.875rem;
      line-height: 1.5;
    }
  
    &__comparison {
      background-color: black;
      border-radius: 24px;
      overflow: hidden;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
      @media (max-width: $breakpoint-lg) {
        max-width: 600px;
        margin: 0 auto;
        width: 100%;
      }
    }
  
    &__comparison-image {
      width: 100%;
      height: auto;
      display: block;
    }
  
    @media (max-width: $breakpoint-md) {
      padding: 4rem 1.5rem;
  
      &__features {
        gap: 1rem;
      }
  
      &__feature {
        align-items: flex-start;
      }
  
      &__check {
        margin-top: 3px;
      }
    }
  }
  