@import '../../scss/variables';

.navbar {
  background-color: white;
  border-bottom: 1px solid $gray-light;
  position: relative;
  
  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__brand {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__logo {
    height: 2rem;
  }

  &__links {
    display: flex;
    gap: 2rem;
    align-items: center;

    @media (max-width: $breakpoint-md) {
      display: none;
      position: absolute;
      top: 4rem;
      left: 0;
      right: 0;
      background-color: white;
      flex-direction: column;
      padding: 1rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 100;

      &--open {
        display: flex;
      }
    }
  }

  &__link {
    color: $gray-dark;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 500;
    transition: color 0.3s ease;

    &:hover {
      color: $burgundy;
    }

    &--active {
      color: $burgundy;
    }

    @media (max-width: $breakpoint-md) {
      padding: 0.75rem 1rem;
      width: 100%;
      text-align: left;
    }
  }

  &__language {
    position: relative;
    
    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
    
    &-button {
      background: none;
      border: none;
      color: $gray-dark;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      padding: 0.75rem 1rem;
      padding-left: 0px;
      transition: color 0.3s ease;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      justify-content: flex-start;

      &:hover,
      &--active {
        color: $burgundy;
      }

      @media (max-width: $breakpoint-md) {
        justify-content: space-between;
      }
    }

    &-dropdown {
      @media (min-width: $breakpoint-md) {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        min-width: 120px;
        padding: 0.5rem 0;
        z-index: 100;

        &::before {
          content: '';
          position: absolute;
          top: -4px;
          right: 1rem;
          width: 8px;
          height: 8px;
          background-color: white;
          transform: rotate(45deg);
          box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.05);
        }
      }

      @media (max-width: $breakpoint-md) {
        position: static;
        box-shadow: none;
        padding: 0;
        background: rgba($burgundy, 0.05);
      }
    }

    &-option {
      display: block;
      width: 100%;
      text-align: left;
      padding: 0.75rem 1rem;
      color: $gray-dark;
      text-decoration: none;
      font-size: 0.875rem;
      transition: background-color 0.3s ease;
      border: none;
      background: none;
      cursor: pointer;

      &--active {
        background-color: rgba($burgundy, 0.1);
        color: $burgundy;
      }

      &:hover {
        background-color: $pink-light;
        color: $burgundy;
      }

      @media (max-width: $breakpoint-md) {
        padding: 0.75rem 2rem;
      }
    }
  }

  &__mobile-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;

    @media (max-width: $breakpoint-md) {
      display: block;
    }

    &-icon {
      display: block;
      width: 1.5rem;
      height: 2px;
      background-color: $burgundy;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $burgundy;
        transition: transform 0.3s ease;
      }

      &::before {
        top: -6px;
      }

      &::after {
        bottom: -6px;
      }
    }
  }
}
