@import '../../scss/variables';

.page-header {
    background: linear-gradient(to right, #67171D, #E57373);
    padding: 4rem 2rem;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(circle at center, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
    }
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  
    &__title {
      color: white;
      font-size: 2.5rem;
      font-weight: 700;
      margin: 0;
      text-align: center;
      font-family: 'Poppins', sans-serif;
    }
  
    &__breadcrumbs {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      color: rgba(255, 255, 255, 0.9);
      font-size: 1rem;
    }
  
    &__breadcrumb {
      &-link {
        color: rgba(255, 255, 255, 0.9);
        text-decoration: none;
        transition: color 0.3s ease;
  
        &:hover {
          color: white;
        }
      }
  
      &-separator {
        font-size: 1.2rem;
        line-height: 1;
        color: rgba(255, 255, 255, 0.7);
      }
  
      &-current {
        color: rgba(255, 255, 255, 0.9);
      }
    }
  
    @media (max-width: $breakpoint-md) {
      padding: 3rem 1.5rem;
  
      &__title {
        font-size: 2rem;
      }
  
      &__breadcrumbs {
        font-size: 0.9rem;
      }
    }
  
    @media (max-width: $breakpoint-sm) {
      padding: 2.5rem 1rem;
  
      &__title {
        font-size: 1.75rem;
      }
    }
  }
  