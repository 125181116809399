@import '../../scss/variables';

.home-gallery {
    background-color: #fff;
    padding: 6rem 2rem;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
      align-items: start;
  
      @media (max-width: $breakpoint-lg) {
        grid-template-columns: 1fr;
        gap: 3rem;
      }
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  
    &__title {
      font-size: 2.5rem;
      font-weight: 700;
      color: $burgundy;
      line-height: 1.2;
      margin: 0;
  
      @media (max-width: $breakpoint-md) {
        font-size: 2rem;
      }
    }
  
    &__text {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
  
      p {
        font-size: 1rem;
        line-height: 1.6;
        color: $gray-dark;
        margin: 0;
      }
    }
  
    &__images {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(6, 1fr);
      gap: 1rem;
      min-height: 600px;
    }
  
    &__image-large {
      grid-column: 1 / 6;
      grid-row: 1 / 5;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
    }
  
    &__image-grid {
      grid-column: 1 / 6;
      grid-row: 5 / 7;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  
    &__image-grid-right {
      grid-column: 6 / 9;
      grid-row: 1 / 7;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      gap: 1rem;
    }
  
    &__image {
      overflow: hidden;
      height: 100%;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
  
      &--top, &--bottom {
        height: 100%;
      }
  
      &--top-right, &--bottom-right {
        height: 100%;
      }
    }
  
    @media (max-width: $breakpoint-lg) {
      &__images {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 1rem;
        min-height: auto;
      }
  
      &__image-large {
        grid-column: auto;
        grid-row: auto;
      }
  
      &__image-grid {
        grid-column: auto;
        grid-row: auto;
        grid-template-columns: repeat(2, 1fr);
      }
  
      &__image-grid-right {
        grid-column: auto;
        grid-row: auto;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
      }
    }
  
    @media (max-width: $breakpoint-md) {
      padding: 4rem 1.5rem;
  
      &__images {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
  
      &__image-grid, 
      &__image-grid-right {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
  
      &__image {
        aspect-ratio: 16/9;
      }
    }
  }
  
  