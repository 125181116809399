@import '../../scss/variables';

.publication-section {
    padding: 4rem 2rem;
  
    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 2rem;
      max-width: 1200px;
      margin: 0 auto;
    }
  }