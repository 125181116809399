@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
$font-family-base: 'Poppins', sans-serif;

body {
    font-family: $font-family-base;
  }
  
  // Optional: Create font-weight variables
  $font-light: 300;
  $font-regular: 400;
  $font-medium: 500;
  $font-semibold: 600;
  $font-bold: 700;
  
  // Optional: Create utility classes
  .font-light {
    font-weight: $font-light;
  }
  
  .font-regular {
    font-weight: $font-regular;
  }
  
  .font-medium {
    font-weight: $font-medium;
  }
  
  .font-semibold {
    font-weight: $font-semibold;
  }
  
  .font-bold {
    font-weight: $font-bold;
  }