@import '../../scss/variables';

.contact {
  padding: 4rem 2rem;
  background-color: white;

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: start;

    @media (max-width: $breakpoint-lg) {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
  }

  &__image-wrapper {
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    padding: 1rem;
  }

  &__image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__title {
    font-size: 2.5rem;
    color: $burgundy;
    font-weight: 700;
    margin: 0;
  }

  &__description {
    font-size: 1.125rem;
    color: $gray-dark;
    margin: 0;
    line-height: 1.6;
  }

  &__info {
    display: flex;
    gap: 2rem;
    margin-bottom: 0.5rem;

    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: $burgundy;
    font-size: 1rem;
  }

  &__info-icon {
    width: 20px;
    height: 20px;
    color: #fff;
  }

  &__info-icon-container {
    background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: $breakpoint-sm) {
      grid-template-columns: 1fr;
    }
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__form-input {
    padding: 1rem;
    border: 1px solid rgba(#fff, 0.2);
    // border: 0px;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    outline: none;
    box-shadow: 0px 1px 22px 0px rgba(0, 0, 0, 0.12);

    &:focus {
      border-color: $burgundy;
    }

    &--error {
      border-color: #dc3545;
    }
  }

  &__form-textarea {
    min-height: 120px;
    resize: vertical;
  }

  &__form-error {
    font-size: 0.675rem;
    color: #dc3545;
  }

  &__form-submit {
    background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 999px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;

    &:hover {
      background-color: darken($burgundy, 10%);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  @media (max-width: $breakpoint-md) {
    padding: 4rem 1.5rem;

    &__title {
      font-size: 2rem;
    }
  }
}