@import '../../scss/variables';

.product-card {
    background-color: white;
    border-radius: 16px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    box-shadow: 0px 0px 15px 0px rgba(153, 1, 1, 0.1);

    &__icon-wrapper {
      width: 48px;
      height: 48px;
      background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    &__icon {
      width: 24px;
      height: 24px;
      color: white;
    }
  
    &__title {
      color: $burgundy;
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0;
    }
  
    &__description {
      color: $gray-dark;
      font-size: 0.9rem;
      line-height: 1.6;
      margin: 0;
    }
  }
  