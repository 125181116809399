@import '../../scss/variables';

.footer {
    background-color: #4A0909;
    color: white;
    padding: 4rem 2rem 2rem;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
    }
  
    &__content {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      gap: 4rem;
      padding-bottom: 3rem;
      border-bottom: 1px solid rgba(white, 0.1);
  
      @media (max-width: $breakpoint-lg) {
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
      }
  
      @media (max-width: $breakpoint-md) {
        grid-template-columns: 1fr;
        gap: 2rem;
      }
    }
  
    &__company {
      max-width: 400px;
  
      @media (max-width: $breakpoint-md) {
        max-width: 100%;
      }
    }
  
    &__logo {
      margin-bottom: 1.2rem;
  
      img {
        height: 40px;
        width: auto;
      }
    }
  
    &__description {
      font-size: 0.875rem;
      line-height: 1.6;
      margin: 0 0 1.5rem;
      color: rgba(white, 0.9);
    }
  
    &__social {
      display: flex;
      gap: 1rem;
    }
  
    &__social-link {
      color: white;
      transition: opacity 0.3s ease;
  
      &:hover {
        opacity: 0.8;
      }
    }
  
    &__title {
      font-size: 1.125rem;
      font-weight: 600;
      margin: 0 0 1.5rem;
      color: white;
    }
  
    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
  
      li {
        font-size: 0.875rem;
      }
  
      a {
        color: rgba(white, 0.9);
        text-decoration: none;
        transition: color 0.3s ease;
  
        &:hover {
          color: white;
        }
      }

      &--contact {
        li {
          margin-bottom: 1rem;
        }
      }
    }

    &__contact-link {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        color: rgba(white, 0.9);
        text-decoration: none;
        transition: color 0.3s ease;
    
        &:hover {
          color: white;
        }
      }
    
      &__contact-icon {
        flex-shrink: 0;
      }
    
      @media (max-width: $breakpoint-md) {
        padding: 3rem 1.5rem 1.5rem;
    
        &__content {
          text-align: center;
        }
    
        &__social {
          justify-content: center;
        }
    
        &__contact-link {
          justify-content: center;
        }
      }
  
    &__bottom {
      padding-top: 1.5rem;
      text-align: center;
    }
  
    &__copyright {
      font-size: 0.875rem;
      color: rgba(white, 0.9);
      margin: 0;
    }
  
    @media (max-width: $breakpoint-md) {
      padding: 3rem 1.5rem 1.5rem;
  
      &__content {
        text-align: center;
      }
  
      &__social {
        justify-content: center;
      }
    }
  }