.supporters-card {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  
    &__logo {
      max-width: 100%;
      max-height: 60px;
      object-fit: contain;
    }
  }
  