@import '../../scss/variables';

.product-section-four {
    padding: 6rem 2rem;
    background-color: white;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }
  
    &__intro {
      text-align: center;
      font-size: 1.25rem;
      color: $gray-dark;
      max-width: 900px;
      margin: 0 auto;
      line-height: 1.6;
    }
  
    &__healthcare-banner {
      position: relative;
      width: 100%;
    //   background-color: #E6F4F7;
      border-radius: 24px;
      overflow: hidden;
      aspect-ratio: 16/9;
    }

    .product-section-four {
        &__video-container {
          position: relative;
          width: 100%;
          border-radius: 24px;
          overflow: hidden;
          aspect-ratio: 16/9;
          background: black;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
          }
        }
      
        &__video-player {
          width: 100%;
          height: 100%;
        }
      }
  
    &__banner-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    //   background-color: #003B4A;
      padding: 2rem;
      border-radius: 8px;
      text-align: center;
      max-width: 600px;
      width: 90%;
      z-index: 2;
    }
  
    &__banner-title {
      color: #FFD700;
      font-size: 2.5rem;
      font-weight: 700;
      margin: 0 0 1rem;
    }
  
    &__banner-text {
      color: white;
      font-size: 1.25rem;
      margin: 0;
      line-height: 1.4;
    }
  
    &__banner-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    //   opacity: 0.1;
    }
  
    &__app-showcase {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
      align-items: center;
  
      @media (max-width: $breakpoint-lg) {
        grid-template-columns: 1fr;
        gap: 3rem;
      }
    }
  
    &__app-images {
      position: relative;
      
    }
  
    &__phones {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      gap: 1rem;

    }
  
    &__phone {
      width: auto;
      height: 400px;
      border-radius: 24px;
    //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
      @media (max-width: $breakpoint-md) {
        height: 300px;
      }
    }
  
    &__app-content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  
    &__app-description {
      font-size: 0.9rem;
      color: $gray-dark;
      line-height: 1.6;
      margin: 0;
    }
  
    @media (max-width: $breakpoint-md) {
      padding: 4rem 1.5rem;
  
      &__intro {
        font-size: 1.125rem;
      }
  
      &__banner-content {
        padding: 1.5rem;
      }
  
      &__banner-title {
        font-size: 2rem;
      }
  
      &__banner-text {
        font-size: 1rem;
      }
    }
  
    @media (max-width: $breakpoint-sm) {
      &__phones {
        flex-direction: column;
        align-items: center;
        transform: none;
      }
  
      &__phone {
        height: 250px;
      }
    }
  }