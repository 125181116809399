@import '../../scss/variables';

.product-section-five {
    padding: 4rem 2rem;
    background-color: $pink-lighter;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }
  
    &__title {
      color: $burgundy;
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin: 0;
  
      @media (max-width: $breakpoint-md) {
        font-size: 2rem;
      }
    }
  
    &__innovations {
      display: flex;
      flex-direction: column;
      gap: 3rem;
  
      @media (max-width: $breakpoint-md) {
        gap: 4rem;
      }
    }
  }
  
  .product-item {
    display: flex;
    gap: 6rem;
    align-items: center;
    justify-content: space-between;

  
    &--reverse {
      flex-direction: row-reverse;
    }
  
    &__content {
      flex: 0 0 45%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding-top: 2rem;
    }
  
    &__title {
      color: $burgundy;
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
    }
  
    &__description {
      color: $gray-dark;
      font-size: 1rem;
      line-height: 1.6;
      margin: 0;
      max-width: 400px;
    }
  
    &__buttons {
      display: flex;
      gap: 1rem;
      margin-top: 0.5rem;
    }
  
    &__button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 2rem;
      border-radius: 999px;
      font-size: 0.875rem;
      font-weight: 500;
      transition: all 0.3s ease;
      cursor: pointer;
      border: none;
  
      &:not(&--outline) {
        background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
        color: white;
  
        &:hover {
          background-color: darken($burgundy, 10%);
        }
      }
  
      &--outline {
        background-color: white;
        border: 1px solid #E37070;
        color: #E37070;
  
        &:hover {
          background-color: rgba($burgundy, 0.05);
        }
  
        .button-icon {
          color: #E37070;
        }
      }
    }
  
    &__image-wrapper {
      flex: 0 0 40%;
      position: relative;
      border-radius: 24px 24px 0 24px;
      padding: 8px;
    //   background-color: white;
    //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
      &::after {
        // content: '';
        // position: absolute;
        // inset: -1px;
        // border: 1px solid rgba($burgundy, 0.1);
        // border-radius: 24px 24px 0 24px;
        // pointer-events: none;
      }
    }
  
    &__image {
      width: 90%;
      height: auto;
      border-radius: 20px 20px 0 20px;
      display: block;
    //   aspect-ratio: 4/3;
      object-fit: cover;
    }
  
    @media (max-width: $breakpoint-lg) {
      gap: 4rem;
    }
  
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
  
      &--reverse {
        flex-direction: column;
      }
  
      &__content {
        flex: 0 0 auto;
        width: 100%;
        padding-top: 0;
      }
  
      &__image-wrapper {
        flex: 0 0 auto;
        width: 100%;
        max-width: 400px;
      }
  
      &__description {
        max-width: none;
      }
    }
  
    @media (max-width: $breakpoint-sm) {
      &__buttons {
        flex-direction: column;
        width: 100%;
        max-width: 200px;
      }
  
      &__button {
        justify-content: center;
      }
    }
  }