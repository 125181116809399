@import '../../scss/variables';

.about {
  background-color: #fff;
  padding: 4rem 2rem;

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 700px;
    gap: 6rem;
    align-items: center;

    @media (max-width: $breakpoint-lg) {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
  }

  &__images {
    position: relative;
    width: 100%;
    padding-top: 2rem;
    padding-right: 2rem;
  }

  &__main-image {
    position: relative;
    width: 100%;
    border-radius: 24px;
    // background-color: white;
    padding: 8px;
    // box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);

    img {
      width: 100%;
      height: auto;
      border-radius: 16px;
      display: block;
    }
  }

  &__experience-badge {
    position: absolute;
    left: 0;
    bottom: 5rem;
    background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
    color: white;
    border: 4px solid #fff;
    padding: 0.8rem 2.5rem;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
    transform: translateX(-25%);
    white-space: nowrap;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &__secondary-image {
    position: absolute;
    top: 200px;
    right: -50px;
    width: 45%;
    border-radius: 24px;
    // background-color: white;
    padding: 8px;
    // box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);

    img {
      width: 100%;
      height: auto;
      border-radius: 16px;
      display: block;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__title {
    color: $burgundy;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
  }

  &__description {
    color: $gray-dark;
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
    margin-bottom: .5rem;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__card {
    background-color: $pink-lighter;
    border-radius: 16px;
    padding: 0.8rem 1rem;
    border: 1px solid #E37070;
    display: flex;
    align-items: start;
    gap: 20px;
    
    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    &-icon {
    //   width: 34px;
    //   height: 34px;
      color: #fff;
      opacity: 0.9;
    }

    &-container {
        background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
        padding: 15px 15px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-title {
      color: #000;
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0;
      margin-bottom: 10px;
    }

    &-text {
      color: $gray-dark;
      font-size: 1rem;
      line-height: 1.6;
      margin: 0;
    }
  }

  @media (max-width: $breakpoint-lg) {
    &__images {
      max-width: 450px;
      margin: 0 auto;
    }

    &__content {
      text-align: center;
    }

    &__card {
      &-header {
        justify-content: center;
      }
    }
  }
}