@import '../../scss/variables';

// HomeServicesCard.scss
@import '../../scss/variables';

.services-card {
  background-color: white;
  border-radius: 16px;
  padding: .8rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  overflow: hidden;
  height: 100%;
  border: 1px solid #E37070;

  &__icon {
    width: 48px;
    height: 48px;
    background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    &-image {
      width: 34px;
      height: 34px;
      object-fit: contain;
      filter: brightness(0) invert(1); // Makes the image white
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  &__title {
    color: $burgundy;
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
  }

  &__subtitle {
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }

  &__description {
    color: $gray-dark;
    font-size: 0.9rem;
    line-height: 1.6;
    margin: 0;
  }
}
