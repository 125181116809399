@import '../../scss/variables';

.future-item {
    display: flex;
    gap: 10rem;
    align-items: center;
    
    &--reverse {
      flex-direction: row-reverse;
    }
  
    &__image-container {
      flex: 0 0 400px;
      position: relative;
      
    }
  
    &__image {
      width: 100%;
      height: auto;
      border-radius: 20px 20px 0 20px;
      position: relative;
      z-index: 2;
      display: block;
    }
  
    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    &__title {
      color: $burgundy;
      font-size: 1.375rem;
      font-weight: 600;
      margin: 0;
    }
  
    &__description {
      color: $gray-dark;
      font-size: 1rem;
      line-height: 1.6;
      margin: 0;
    }
  
    &__link {
      color: $burgundy;
      text-decoration: none;
      font-size: 0.9rem;
      
      &:hover {
        text-decoration: underline;
      }
    }
  
    @media (max-width: $breakpoint-lg) {
      flex-direction: column;
      gap: 2rem;
      text-align: center;
  
      &--reverse {
        flex-direction: column;
      }
  
      &__image-container {
        flex: 0 0 auto;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }
  