@import '../../scss/variables';

.strategy-card {
    background-color: white;
    border-radius: 16px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    }
  
    &__icon {
      width: 48px;
      height: 48px;
      background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-bottom: 10px;
  
      svg {
        width: 24px;
        height: 24px;
      }
    }
  
    &__title {
      color: $burgundy;
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0;
      line-height: 1.4;
    }
  
    &__description {
      color: $gray-dark;
      font-size: 0.9rem;
      line-height: 1.6;
      margin: 0;
      flex-grow: 1;
    }
  }
  