@import '../../scss/variables';

.home-futures {
    background-color: #fff;
    padding: 6rem 2rem;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
    }
  
    &__header {
      text-align: center;
      max-width: 800px;
      margin: 0 auto 4rem;
    }
  
    &__title {
      color: $burgundy;
      font-size: 2.5rem;
      font-weight: 700;
      margin: 0 0 1.5rem;
      line-height: 1.2;
  
      @media (max-width: $breakpoint-md) {
        font-size: 2rem;
      }
    }
  
    &__subtitle {
      color: $gray-dark;
      font-size: 1.125rem;
      line-height: 1.6;
      margin: 0;
    }
  
    &__items {
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }
  
    @media (max-width: $breakpoint-md) {
      padding: 4rem 1.5rem;
  
      &__header {
        margin-bottom: 3rem;
      }
  
      &__items {
        gap: 4rem;
      }
    }
  }