@import '../../scss/variables';

.home-services {
    background-color: $pink-lighter;
    padding: 4rem 2rem;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  
    &__header {
      text-align: center;
      max-width: 900px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  
    &__title {
      color: $burgundy;
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.2;
      margin: 0;
  
      @media (max-width: $breakpoint-md) {
        font-size: 2rem;
      }
    }
  
    &__subtitle {
      color: $gray-dark;
      font-size: 0.9rem;
      line-height: 1.6;
      margin: 0;
    }
  
    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
  
      @media (max-width: $breakpoint-lg) {
        grid-template-columns: repeat(2, 1fr);
      }
  
      @media (max-width: $breakpoint-md) {
        grid-template-columns: 1fr;
        max-width: 500px;
        margin: 0 auto;
      }
    }
  }