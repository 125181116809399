// Banner.scss
@import '../../scss/variables';

.banner {
    background-color: $pink-light;
    padding: 4rem 2rem;
    min-height: 500px;
    display: flex;
    align-items: center;
    overflow: hidden;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
      align-items: center;
      position: relative;
  
      @media (max-width: $breakpoint-md) {
        grid-template-columns: 1fr;
        text-align: center;
        gap: 2rem;
      }
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      max-width: 540px;
    }
  
    &__title {
      font-size: 2.8rem;
      font-weight: 700;
      color: $burgundy;
      line-height: 1.2;
      margin: 0;
  
      @media (max-width: $breakpoint-md) {
        font-size: 2rem;
      }
    }
  
    &__subtitle {
      font-size: 1.25rem;
      color: #000;
      margin: 0;
    }
  
    &__button {
      display: inline-block;
      background: linear-gradient(97.65deg, #E37070 0.33%, #883232 93.35%);
      color: white;
      padding: 1rem 2rem;
      border-radius: 9999px;
      font-weight: 500;
      transition: all 0.3s ease;
      border: none;
      cursor: pointer;
      width: fit-content;
      box-shadow: 0px 10px 15px 0px rgba(153, 1, 1, 0.2);
  
      &:hover {
        background-color: darken($red-primary, 5%);
        transform: translateY(-2px);
        box-shadow: 0 6px 12px rgba(229, 115, 115, 0.4);
      }
  
      @media (max-width: $breakpoint-md) {
        margin: 0 auto;
      }
    }
  
    &__images-container {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 400px;
    }
  
    &__background-shape {
      position: absolute;
      top: -20%;
      right: -10%;
      width: 100%;
      height: 140%;
    //   background-color: $burgundy;
      border-radius: 0 0 0 140px;
      z-index: 1;
  
      @media (max-width: $breakpoint-md) {
        right: -5%;
        height: 120%;
      }
    }
  
    &__main-image {
      position: relative;
      width: 90%;
      margin-left: auto;
      z-index: 2;
      border-radius: 24px;
      overflow: hidden;
    //   background-color: white;
      padding: 8px;
    //   box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  
      img {
        width: 100%;
        height: auto;
        border-radius: 16px;
        display: block;
      }
    }
  
    &__secondary-image {
      position: absolute;
      bottom: 10%;
      right: -10%;
      width: 180px;
      z-index: 3;
      border-radius: 24px;
      overflow: hidden;
    //   background-color: white;
      padding: 8px;
    //   box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  
      img {
        width: 100%;
        height: auto;
        border-radius: 16px;
        display: block;
      }
  
      @media (max-width: $breakpoint-md) {
        width: 140px;
        right: 0;
      }
    }
  }
// .banner {
//   background-color: $pink-light;
//   padding: 4rem 2rem;
//   min-height: 500px;
//   display: flex;
//   align-items: center;

//   &__container {
//     max-width: 1200px;
//     margin: 0 auto;
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     gap: 2rem;
//     align-items: center;

//     @media (max-width: $breakpoint-md) {
//       grid-template-columns: 1fr;
//       text-align: center;
//     }
//   }

//   &__content {
//     display: flex;
//     flex-direction: column;
//     gap: 1.5rem;
//   }

//   &__title {
//     font-size: 2.5rem;
//     font-weight: 700;
//     color: $burgundy;
//     line-height: 1.2;

//     @media (max-width: $breakpoint-md) {
//       font-size: 2rem;
//     }
//   }

//   &__subtitle {
//     font-size: 1.25rem;
//     color: $gray-dark;
//   }

//   &__button {
//     display: inline-block;
//     background-color: $red-primary;
//     color: white;
//     padding: 1rem 2rem;
//     border-radius: 9999px;
//     font-weight: 500;
//     transition: background-color 0.3s ease;
//     border: none;
//     cursor: pointer;
//     width: fit-content;

//     &:hover {
//       background-color: darken($red-primary, 10%);
//     }

//     @media (max-width: $breakpoint-md) {
//       margin: 0 auto;
//     }
//   }

//   &__images {
//     position: relative;
//     padding-bottom: 2rem;
//   }

//   &__main-image {
//     background-color: $burgundy;
//     padding: 0.5rem;
//     border-radius: 1rem;
//     overflow: hidden;
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

//     img {
//       width: 100%;
//       height: auto;
//       border-radius: 0.75rem;
//       display: block;
//     }
//   }

//   &__secondary-image {
//     position: absolute;
//     bottom: 0;
//     right: -1rem;
//     width: 8rem;
//     height: 8rem;
//     border-radius: 0.75rem;
//     overflow: hidden;
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

//     img {
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//     }
//   }
// }