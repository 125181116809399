@import '../../scss/variables';

.publication-detail {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;

    &__layout {
        display: grid;
        grid-template-columns: 1fr 300px;
        gap: 3rem;
        margin-top: 2rem;
    
        @media (max-width: $breakpoint-lg) {
          grid-template-columns: 1fr;
        }
      }

      &__header {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;
    
        &-image {
          width: 100%;
          height: auto;
          border-radius: 16px;
        }
      }

      &__sidebar {
        position: sticky;
        top: 2rem;
        height: fit-content;
      }

      &__social {
        margin-bottom: 3rem;
    
        h3 {
          color: $burgundy;
          margin-bottom: 1rem;
        }
    
        &-links {
          display: flex;
          gap: 1rem;
          
          a {
            color: $burgundy;
            transition: opacity 0.3s;
    
            &:hover {
              opacity: 0.8;
            }
          }
        }
    }
  
    &__breadcrumb {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 1rem;
      color: $gray-dark;
    }
  
    &__date {
      color: $gray-dark;
      margin-bottom: 2rem;
    }
  
    &__title {
      font-size: 2rem;
      color: $burgundy;
      margin-bottom: 2rem;
    }
  
    &__main-image {
      width: 100%;
      border-radius: 16px;
      overflow: hidden;
      margin-bottom: 2rem;
  
      img {
        width: 100%;
        height: auto;
      }
    }
  
    &__social {
      margin-bottom: 2rem;

      h3 {
        font-size: 24px;
      }
  
      &-links {
        display: flex;
        gap: 1rem;
        color: $burgundy;
      }
    }
  
    &__text {
      font-size: 1rem;
      line-height: 1.8;
      color: $gray-dark;
  
      h4 {
        color: $burgundy;
        margin: 2rem 0 1rem;
      }
    }
  
    &__reference {
      font-style: italic;
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid rgba($burgundy, 0.1);
    }
  }
  
  .sidebar_title {
    color: $burgundy !important;
    font-size: 24px;
  }