@import '../../scss/variables';

.publication-card {
    background: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 15px 0px rgba(153, 1, 1, 0.1);
    transition: transform 0.3s ease;
    border: 2px solid rgba(245, 195, 195, 1);
    :hover {
        cursor: pointer;
    }
  
    &__image {
      width: 100%;
      aspect-ratio: 16/9;
      overflow: hidden;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    &__content {
      padding: 1.5rem;
    }
  
    &__title {
      color: $burgundy;
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
  
    &__description {
      color: $gray-dark;
      font-size: 0.875rem;
      line-height: 1.6;
      margin-bottom: 1rem;
    }
  
    &__date {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: $gray-dark;
      font-size: 0.875rem;

      border-top: 1px solid var(--Color02, rgba(249, 220, 220, 1));
      padding-top: 15px;
    }
  }